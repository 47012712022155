import React from 'react'
import './App.css'
// Import components
// import Nav from './components/Nav'
// import Tweets from './components/Tweets'
import Message from './components/Message'
import Counter from './components/Counter'
import Hello from './components/Hello'

class App extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      mount: true
    }
    this.mountCounter = () => this.setState({ mount: true })
    this.unMountCounter = () => this.setState({ mount: false })
  }

  render () {
    const name = 'react'
    return (
      <div className='App'>
        <Hello name={name} />
        <Message />
        {this.state.mount ? <Counter /> : null}
        <div className='center'>'<button onClick={this.mountCounter} disabled={this.state.mount}>Show Counter</button>
          <button onClick={this.unMountCounter} disabled={!this.state.mount}>Hide Counter</button>
        </div>
      </div>
    )
  }
}

export default App
